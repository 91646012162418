@import url('https://fonts.googleapis.com/css2?family=Cantarell&family=Ubuntu&display=swap');
@import url("https://use.typekit.net/bwd5ltp.css");

// these align with bootstrap breaks
@mixin sm-up() {
  @media (min-width: 576px) {
      @content;
  }
}
@mixin md-up() {
  @media (min-width: 768px) {
      @content;
  }
}
@mixin lg-up() {
  @media (min-width: 992px) {
      @content;
  }
}
@mixin xl-up() {
  @media (min-width: 1200px) {
      @content;
  }
}
@mixin xxl-up() {
  @media (min-width: 1600px) {
      @content;
  }
}

.App {
  text-align: center;
  font-family: 'Cantarell', sans-serif; 
  min-height: 100vh;

  @include lg-up(){ font-size: 18px; }
  @include xl-up(){ font-size: 24px; }
  @include xxl-up(){ font-size: 28px; }

  > * {
    max-width: 800px;
    padding-left: 2em;
    padding-right: 2em;
    margin-left: auto;
    margin-right: auto;
    display: block;
    opacity: 1;
    transform: none;

    @include xl-up {
      max-width: 1080px;
    }
    @include xxl-up {
      max-width: 1280px;
    }
  }

  h1, h2 {
    font-family: "chainprinter", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  h1 { font-size: 1em; }
  h2 {
    font-size: 2em;
  }
  input, select, button {
    font-family: 'Ubuntu', sans-serif;
    padding: .5em 1em;
    font-size: 1em;
    box-sizing: border-box;

    &.error { border-color: #f00; }
  }
  label.error { color: #f00; }
  button {
    background-color: #191919;
    border-radius: 0;
    color: #fff;
    padding: 1em 3em;
    cursor: pointer;

    &:hover {
      background-color: #494949;
    }
    &:active {
      color: #aaa;
    }
  }

  
  .six-lock {
    margin-top: 2em;
    margin-bottom: 2em;
    max-width: 16em;
  }

  .age-gate {
    // max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    .age-gate-row {
      display: flex;
      flex-direction: column;
      gap: 1em;
      justify-content: center;
      align-items: stretch;
      margin: 2em auto;
      // max-width: 450px;
      width: 80%;
      @include sm-up {  width: 40%; }

      button {
        font-size: 1.2em;
        font-weight: bold;
        background-color: #fff;
        color: #191919;
        span { 
          display: block;
          font-size: .8em;
          font-weight: normal;
        }
        padding: .6em;

        &:hover {
          background-color: #aaa;
        }
        &:active {
          color: #494949;
        }
      }
    }
  }

  .cans {
    max-width: calc(100% + 2rem);
    margin-left: -3rem;
    margin-right: -3rem; 
    padding: 0;
    @include sm-up {
      max-width: 762px;
      margin-left: auto;
      margin-right: auto;
    } 
  }
  .mixpack { 
    max-width: 80%;
    box-sizing: border-box;
    @include sm-up { max-width: 350px; }
  }

  .steps-row {
    display: flex;
    gap: 2em;
    flex-direction: column;
    @include sm-up { flex-direction: row; }

    .steps-cell {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      h4 { margin-bottom: 0; }
      p { margin-top: 0; flex: 1; }
      img {
        max-width: 80%;
        @include sm-up { max-width: 100%; }
      }
    }
  }

  > button {
    margin: 3em auto;
  }

  .date-select-row {
    h4 { margin-bottom: .5em; }
    button {
      background-color: #fff;
      color: #191919;
      border: 2px solid #191919;
      margin: .25em;
      width: 80%;
      padding-top: .5em;
      padding-bottom: .5em;
      @include sm-up { width: unset; }

      &.selected {
        background-color: #191919;
        color: #fff;
      }
      &.disabled {
        pointer-events: none;
        opacity: .3;
      }
    }
  }

  .form-row, .birthday-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;

    input {
      flex-grow: 1;
      @include sm-up { max-width: calc(50% - .5em); min-width: 35%; }
    }
    small {
      flex: 100%;
    }
  }
  .optin-row {
    margin-top: 2em;
    div {
      margin-top: 1em;
      label.error { color: unset; }
      span { display: block; margin-top: .5em; }
    }
  }
  .birthday-row {
    h4 { flex: 100%; margin-bottom: 0; }
    input {
      flex-grow: 1;
      @include sm-up { max-width: calc(33% - .66em);  min-width: unset; }

      /* Chrome, Safari, Edge, Opera */
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      &[type=number] {
        -moz-appearance: textfield;
      }
    }
  }
  .error { color: #ff0000; }
  span.error { font-size: .8em; }
  .error-row { span { display: block; } }

  .date-box {
    background-color: #191919;
    color: #fff;
    display: inline-block;
    padding: 1em 3em;
  }
  
  .social-row {
    a {
      margin: 1em;
      &:hover {
        opacity: .7;
      }
    }
    img {
      width: 3em;
      height: auto;
    }
  }

  .legal {
    text-align: left;
    font-size: .8em;
  }

  &.dark {
    background-color: #191919;
    color: #fff;

    header a {
      pointer-events: none;
    }
  }
}

.Header-Logo {
  margin-top: 4em;
  max-width: 7em;

  .dark & {
    filter: invert(1);
  }
}
.App footer {
  margin-top: 8em;
  
  font-size: .8em;
  max-width: unset;
  padding-bottom: 1em;

  &.dark {
    background: #191919;
    color: #fff;

    > div {

      &.footer-row { display: flex; }
    }
  }

  > div {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    color: #aaa;

    &.footer-row {
      display: none;
      justify-content: center;
      margin-bottom: 2em;
      flex-direction: column;
      gap: 2em;
      @include sm-up { flex-direction: row; gap: 4em; }
      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
        &:visited {
          color: #fff;
        }
      }
    }

    &.pre-footer {
      text-align: center;
      top: -2em;
      position: relative;
      color: #191919;
      height: 1em;
    }

    @include xl-up {
      max-width: 1280px;
    }
  }
}


// utitlity
.mb-2 { margin-bottom: 2em; }
.mb-4 { margin-bottom: 4em; }
.mb-6 { margin-bottom: 6em; }
.narrow {
  max-width: 600px;
}